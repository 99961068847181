import { useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import HoldAppointment from "./temporaryAppointments/HoldAppointment";
import OnlineBookingContainer from "./onlineBooking/returning/OnlineBookingContainer";
import OnlineBookingLeadContainer from "./onlineBooking/newLead/OnlineBookingLeadContainer";
import { useOnlineBookingContext } from "./onlineBooking/OnlineBookingContext";
import { CustomStepSegment } from "@marathon/common/utilities/OnlineBookingSteps";
import { AdsClickIdType } from "@marathon/common/entities/AdsActivity";
import NotFound from "@marathon/web/components/NotFound";
import BookingSuggestionContainer from "./onlineBooking/BookingSuggestionContainer";
import Unleashed from "./unleashed/Unleashed";

const smsQueryStringKey = "s";
const gclidQueryStringKey = "gclid";
const msclkidQueryStringKey = "msclkid";
const fbclidQueryStringKey = "fbclid";
const gbraidQueryStringKey = "gbraid";
const wbraidQueryStringKey = "wbraid";

export default function Navigations() {
  const { search } = useLocation();
  const { setIsFromSms, setClickId } = useOnlineBookingContext();

  useEffect(() => {
    const query = new URLSearchParams(search);
    setIsFromSms(query.has(smsQueryStringKey));
    getClickId(query);
    //HACK: We only want this useEffect to be triggered once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getClickId = (query: URLSearchParams) => {
    const gclid = query.get(gclidQueryStringKey);
    if (gclid) setClickId({ value: gclid, type: AdsClickIdType.gclid });

    const msclkid = query.get(msclkidQueryStringKey);
    if (msclkid) setClickId({ value: msclkid, type: AdsClickIdType.msclkid });

    const fbclid = query.get(fbclidQueryStringKey);
    if (fbclid) setClickId({ value: fbclid, type: AdsClickIdType.fbclid });

    const gbraid = query.get(gbraidQueryStringKey);
    if (gbraid) setClickId({ value: gbraid, type: AdsClickIdType.gbraid });

    const wbraid = query.get(wbraidQueryStringKey);
    if (wbraid) setClickId({ value: wbraid, type: AdsClickIdType.wbraid });
  };

  return (
    <Switch>
      <Route exact path="/"><Redirect to="/online-booking" /></Route>
      <Route exact path="/appointment/:id" render={() => <HoldAppointment />} />
      <Route exact path="/booking-suggestion/:stepNumber?" render={() => <BookingSuggestionContainer />} />
      <Route exact path="/unleashed/:year" render={() => <Unleashed />} />
      <Route exact path={`/online-booking/${CustomStepSegment.manageAccount}`} render={() => <OnlineBookingContainer customStep={CustomStepSegment.manageAccount} />} />
      <Route exact path={`/online-booking/${CustomStepSegment.referral}`} render={() => <OnlineBookingContainer customStep={CustomStepSegment.referral} />} />
      <Route exact path={`/online-booking/${CustomStepSegment.notificationSettings}`} render={() => <OnlineBookingContainer customStep={CustomStepSegment.notificationSettings} />} />
      <Route exact path={`/online-booking/${CustomStepSegment.addCard}`} render={() => <OnlineBookingContainer customStep={CustomStepSegment.addCard} />} />
      <Route exact path="/online-booking-signup/:stepNumber?/:substepNumber?" render={() => <OnlineBookingLeadContainer />} />
      <Route exact path="/online-booking/:stepNumber?/:substepNumber?" render={() => <OnlineBookingContainer />} />
      <Route><NotFound /></Route>
    </Switch>
  );
}
