import DeviceStorageCache from "@marathon/client-side/utilities/DeviceStorageCache";
import CallableFunctions from "@marathon/client-side/utilities/CallableFunctions";
import { MAX_IN_PARAMETERS_LENGTH } from "@marathon/common/utilities/Firestore";
import { getDatePlusMinutes } from "@marathon/common/helpers/timeHelper";
import { TimeZone, getDatePlusDays } from "@marathon/common/helpers/timeZoneHelper";
import { Appointment, AppointmentData, AppointmentSelectedPet, AppointmentStatus, CancellationReason, PaymentStatus, SystemCancellationReason } from "@marathon/common/entities/Appointment";
import { AppointmentInput } from "../entities/AppointmentInput";
import LocalDate from "@marathon/common/utilities/LocalDate";
import { FilterOperator, getFilter } from "@marathon/common/api/QueryFilter";
import { RecurrenceRepository } from "./RecurrenceRepository";
import { getFutureOccurrences } from "@marathon/common/helpers/rruleHelper";
import { isFirstHourService } from "@marathon/common/helpers/timeFormatHelper";
import { CollectionPaths } from "@marathon/common/entities/base/CollectionPaths";
import { Customer } from "@marathon/common/entities/Customer";
import { Groomer } from "@marathon/common/entities/Groomer";
import { Service } from "@marathon/common/entities/Service";
import { SecondaryAddress } from "@marathon/common/entities/SecondaryAddress";
import { Pet } from "@marathon/common/entities/Pet";
import type { DocResult, IFirestoreService } from "./IFirestoreService";
import { INJECTED_FIRESTORE_SERVICE_TOKEN } from "./IFirestoreService";
import { UpdateDataInternal, deleteFieldInternal } from "@marathon/common/utilities/TypeUtils";
import { container, inject, singleton } from "tsyringe";
import { CustomerMessageRepository } from "./CustomerMessageRepository";
import { User } from "@marathon/common/entities/User";

const mapEntity = function (snapshot: DocResult<AppointmentData>) {
  return new Appointment(snapshot.id, snapshot.data);
};

const updateAudit = (data: UpdateDataInternal<AppointmentData>, user?: string) => {
  if (!data) return;
  data.updated_by = user || DeviceStorageCache.getCurrentUserName() || User.systemUsers.onlineBooking;
  data.updated_at = new Date();
};

const calendarEventStyles = {
  blueBackground: { backgroundColor: "#146bf5", color: "#edf3fe" },
  lightBlueBackground: { backgroundColor: "#bfdcff", color: "#1893ff" },
  lightBlueAccent: { backgroundColor: "#06beff", color: "#edf3fe" },
  lightGrayBackground: { backgroundColor: "#d4d4d4", color: "#000000" },
  yellowBackground: { backgroundColor: "#fff8ba", color: "#000000" },
  lightYellowBackground: { backgroundColor: "#fff6d4", color: "#000000" },
  blackBackground: { backgroundColor: "#000000", color: "#ffffff" },
  redBackground: { backgroundColor: "#be0000", color: "#ffffff" },
  linearGradientBackground: {
    background: `repeating-linear-gradient(
      -45deg,
      #3269ec,
      #3269ec 30px,
      #0a2bda 30px,
      #0a2bda 60px
    )`,
    color: "#ffffff",
  },
  orangeBackground: { backgroundColor: "#ff7e00", color: "#ffffff" },
  defaultBackground: { color: "#000000", background: undefined, backgroundColor: undefined }
};

@singleton()
export class AppointmentRepository {
  private firestoreService: IFirestoreService<AppointmentData>;
  constructor(@inject(INJECTED_FIRESTORE_SERVICE_TOKEN) injectedService: IFirestoreService<AppointmentData>) {
    injectedService.collectionPath = CollectionPaths.Appointments;
    this.firestoreService = injectedService;
  }
  static get current() {
    return container.resolve(AppointmentRepository);
  }
  async getById(id: string) {
    const doc = await this.firestoreService.getById(id);
    return doc ? mapEntity(doc) : null;
  }
  async create(data: AppointmentData) {
    if (await CallableFunctions.current.public.isNewCustomer(data.customer.id)) {
      data.is_new_customer = true;
    }
    if (await CallableFunctions.current.public.isFirstAppointment(data.groomer.id, data.start_time, data.time_zone) && isFirstHourService(data.start_time, data.time_zone)) {
      data.show_exact_time = true;
    }

    const docId = await this.firestoreService.create(data);
    return mapEntity({ id: docId, data });
  }
  async suggestNewTime(originalAppointmentId: string, suggestedAppointmentData: AppointmentData, expiration: number, message: string) {
    const expirationTime = new Date(new Date().getTime() + (expiration) * 60 * 1000);

    const data: AppointmentData = {
      ...suggestedAppointmentData,
      expiration_time: expirationTime,
      status: AppointmentStatus.pending,
      suggestion_for_appointment_id: originalAppointmentId
    };

    const suggestedAppointment = await this.create(data);

    const toUpdate: Partial<AppointmentData> = {
      suggestion_appointment: {
        id: suggestedAppointment.id,
        status: AppointmentStatus.pending
      }
    };
    await this.update(originalAppointmentId, toUpdate);

    await CustomerMessageRepository.current.createSms({
      parentId: suggestedAppointment.customer.id,
      content: message,
      phone: suggestedAppointment.customer.phone,
      suggestedAppointmentId: suggestedAppointment.id,
    });

    return suggestedAppointment;
  }
  async update(id: string, data: UpdateDataInternal<AppointmentData>, user?: string) {
    updateAudit(data, user);
    this.firestoreService.update(id, data);
  }
  async confirmOnHold(id: string) {
    await this.update(id, { status: AppointmentStatus.scheduled });
  }
  async acceptSuggestion(appointment: Appointment) {
    if (!appointment.suggestion_for_appointment_id)
      throw new Error("Original appointment id not found");

    await this.cancel(appointment.suggestion_for_appointment_id, SystemCancellationReason.suggestionAccepted);

    await this.update(appointment.id, {
      status: AppointmentStatus.scheduled,
      suggestion_for_appointment_id: deleteFieldInternal,
    });
  }
  async decline(id: string, user?: string) {
    const data: Partial<AppointmentData> = { status: AppointmentStatus.declined };
    await this.update(id, data, user);
  }
  async declineSuggestion(appointment: Appointment) {
    if (!appointment.suggestion_for_appointment_id)
      throw new Error("Original appointment id not found");

    await this.update(appointment.suggestion_for_appointment_id, { "suggestion_appointment.status": AppointmentStatus.declined } as Partial<AppointmentData>);
    await this.update(appointment.id, { status: AppointmentStatus.declined });
  }
  async toggleUnconfirmedStatus(id: string, isReconfirming: boolean) {
    await this.update(id, {
      status: isReconfirming ? AppointmentStatus.scheduled : AppointmentStatus.unconfirmed,
      unconfirmed: isReconfirming
        ? deleteFieldInternal
        : {
          by: DeviceStorageCache.getNonEmptyCurrentUserName(),
          at: new Date()
        }
    });
  }
  async updateExpirationTime(id: string, minutes: number) {
    if (minutes < 0) return;
    const appointment = await this.getById(id);
    const currentExpirationTime = appointment?.expiration_time ?? new Date();
    const newDate = getDatePlusMinutes(currentExpirationTime, minutes);
    if (newDate < currentExpirationTime) return;
    const data: Partial<AppointmentData> = { expiration_time: newDate };
    await this.update(id, data);
    return newDate;
  }
  async cancel(id: string, reason: CancellationReason | SystemCancellationReason, notifyCustomer?: boolean, collectedFee?: number, notes?: string) {
    const data: Partial<AppointmentData> = {
      status: AppointmentStatus.cancelled,
      cancellation: {
        reason,
        customer_notified: notifyCustomer || undefined,
        collected_fee: collectedFee
      },
      payment_status: collectedFee ? PaymentStatus.paid : undefined,
      notes
    };
    this.update(id, data);
  }
  static getSelectedPetsFromInput(input: AppointmentInput, pets: Pet[]): AppointmentSelectedPet[] {
    return input.selectedPets.map(x => {
      if (!x.petId || !x.serviceId) {
        throw new Error("Invalid appointment pet input");
      }

      const pet = pets.find(p => p.id === x.petId);
      if (!pet) {
        throw new Error("Pet not found");
      }

      if (!pet.breed_id) {
        throw new Error("Pet breed is required at this point");
      }

      const selectedPet: AppointmentSelectedPet = {
        petId: x.petId,
        serviceId: x.serviceId,
        servicePrice: x.servicePrice,
        breedId: pet.breed_id,
        customServiceTime: x.customServiceTime,
        petWeight: pet.weight,
        petName: pet.name
      };

      return selectedPet;
    });
  }
  static getAppointmentToCreate(input: AppointmentInput, groomer: Groomer, customer: Customer, services: Service[], pets: Pet[], selectedSecondaryAddress?: SecondaryAddress | null, isRecreatingRecurrence?: true) {
    const duration = input.getDuration(services);
    const endTime = new Date(input.startTime.getTime() + duration * 60 * 1000);
    const currentUser = DeviceStorageCache.getCurrentUserName();
    const data = {
      customer: customer.getSummaryForAppointment(selectedSecondaryAddress, input.isFromSignup),
      notes: input.notes,
      notes_from_customer: input.notesFromCustomer,
      groomer: groomer.getSummaryForAppointment(),
      selected_pets: AppointmentRepository.getSelectedPetsFromInput(input, pets),
      extra_items: input.extraItems?.map(x => ({ ...x })),
      start_time: input.startTime,
      end_time: endTime,
      created_at: isRecreatingRecurrence ? input.createdAt : new Date(),
      status: input.isOnHold ? AppointmentStatus.pending : AppointmentStatus.scheduled,
      booked_by: isRecreatingRecurrence ? input.bookedBy : currentUser || User.systemUsers.onlineBooking,
      frequency: input.frequency,
      is_from_sms: input.isFromSms,
      attributed_to: input.attributedTo,
      is_from_online_booking: input.isFromOnlineBooking,
      time_zone: groomer.time_zone,
      discounts: input.rescheduleAppointment ? input.rescheduleAppointment.discounts : input.discounts,
      credits: input.rescheduleAppointment ? input.rescheduleAppointment.credits : input.credits,
      updated_at: isRecreatingRecurrence ? new Date() : undefined,
      updated_by: isRecreatingRecurrence ? currentUser : undefined,
      origin: input.origin
    } as AppointmentData;

    if (input.isOnHold) {
      data.expiration_time = new Date(new Date().getTime() + (input.expiration) * 60 * 1000);
    }

    return data;
  }
  static getAppointmentToUpdate(input: AppointmentInput, groomer: Groomer, customer: Customer, services: Service[], pets: Pet[], selectedSecondaryAddress?: SecondaryAddress | null) {
    const duration = input.getDuration(services);
    const endTime = new Date(input.startTime.getTime() + duration * 60 * 1000);
    const data = {
      customer: customer.getSummaryForAppointment(selectedSecondaryAddress, input.isFromSignup),
      selected_pets: AppointmentRepository.getSelectedPetsFromInput(input, pets),
      groomer: groomer.getSummaryForAppointment(),
      start_time: input.startTime,
      end_time: endTime,
      status: input.status,
      payment_status: input.paymentStatus,
      notes: input.notes,
      notes_from_customer: input.notesFromCustomer,
      frequency: input.frequency || undefined,
      extra_items: input.extraItems,
      tip: input.collected && input.isPaid() ? input.collected - input.finalPrice() : undefined,
      service_end_time: !input.serviceEndTime && input.isCompleted() ? new Date() : input.serviceEndTime,
      attributed_to: input.attributedTo,
      time_zone: groomer.time_zone,
      grooming_notes: input.groomingNotes,
      discounts: input.discounts,
      origin: input.origin,
      unconfirmed: input.unconfirmed,
      cancellation: input.cancellation
    } as Partial<AppointmentData>;

    return data;
  }
  async searchPrevious(customerId: string, startTime = new Date(), limit = 10) {
    const docs = await this.firestoreService.search({
      filters: [
        getFilter("customer.id", FilterOperator.equal, customerId),
        getFilter("start_time", FilterOperator.lessThan, startTime),
        getFilter("status", FilterOperator.equal, AppointmentStatus.completed)
      ],
      orders: [{ field: "start_time", direction: "desc" }],
      limit
    });

    return docs.map(x => mapEntity(x));
  }
  async searchFuture(customerId: string) {
    const appointments = await this.searchUpcomingByCustomer(customerId, new Date);
    const recurrences = await RecurrenceRepository.current.searchForCustomer(customerId);
    const futureOccurrences = recurrences.map(x => getFutureOccurrences(x, 1)).flat();
    const allFutureAppointments = [...appointments, ...futureOccurrences].sortByFieldAscending(x => x.start_time);
    return allFutureAppointments;
  }
  async searchForDayAndTimeZone(fromDate: LocalDate, toDate: LocalDate, timeZone: TimeZone) {
    const docs = await this.firestoreService.search({
      filters: [
        getFilter("start_time", FilterOperator.greaterThanOrEqual, fromDate.toDayStart()),
        getFilter("start_time", FilterOperator.lessThanOrEqual, toDate.toDayEnd()),
        getFilter("time_zone", FilterOperator.equal, timeZone)
      ]
    });
    return docs.map(x => mapEntity(x));
  }
  async searchForDayAndGroomer(fromDate: LocalDate, toDate: LocalDate, groomerId: string) {
    const docs = await this.firestoreService.search({
      filters: [
        getFilter("start_time", FilterOperator.greaterThanOrEqual, fromDate.toDayStart()),
        getFilter("start_time", FilterOperator.lessThanOrEqual, toDate.toDayEnd()),
        getFilter("groomer.id", FilterOperator.equal, groomerId)
      ]
    });
    return docs.map(x => mapEntity(x));
  }
  async searchForDayAndHub(fromDate: LocalDate, toDate: LocalDate, hubId: string) {
    const docs = await this.firestoreService.search({
      filters: [
        getFilter("start_time", FilterOperator.greaterThanOrEqual, fromDate.toDayStart()),
        getFilter("start_time", FilterOperator.lessThanOrEqual, toDate.toDayEnd()),
        getFilter("groomer.hub_id", FilterOperator.equal, hubId)
      ]
    });
    return docs.map(x => mapEntity(x));
  }
  async searchForCustomer(customerId: string) {
    const docs = await this.firestoreService.search({
      filters: [
        getFilter("customer.id", FilterOperator.equal, customerId)
      ],
      orders: [{ field: "start_time", direction: "desc" }],
      limit: 50
    });
    return docs.map(x => mapEntity(x));
  }
  async searchForGroomer(groomerId: string) {
    const docs = await this.firestoreService.search({
      filters: [
        getFilter("groomer.id", FilterOperator.equal, groomerId)
      ],
      orders: [{ field: "start_time", direction: "desc" }],
      limit: 50
    });
    return docs.map(x => mapEntity(x));
  }
  async searchFutureExceptions() {
    const docs = await this.firestoreService.search({
      filters: [
        getFilter("start_time", FilterOperator.greaterThan, new Date())
      ]
    });
    const appointments: Appointment[] = [];
    docs.forEach(doc => {
      const appointment = mapEntity(doc);
      if (appointment.status === AppointmentStatus.scheduled && appointment.recurrence_id) {
        appointments.push(appointment);
      }
    });

    return appointments;
  }
  async searchUpcomingByCustomer(customerId: string, date = new Date(), withLimit = 50) {
    const docs = await this.firestoreService.search({
      filters: [
        getFilter("customer.id", FilterOperator.equal, customerId),
        getFilter("start_time", FilterOperator.greaterThan, date),
        getFilter("status", FilterOperator.equal, AppointmentStatus.scheduled)
      ],
      orders: [{ field: "start_time", direction: "asc" }],
      limit: withLimit
    });
    return docs.map(x => mapEntity(x));
  }
  async searchUpcomingByGroomer(groomerId: string, date = new Date(), withLimit = 50) {
    const docs = await this.firestoreService.search({
      filters: [
        getFilter("groomer.id", FilterOperator.equal, groomerId),
        getFilter("start_time", FilterOperator.greaterThan, date),
        getFilter("status", FilterOperator.equal, AppointmentStatus.scheduled)
      ],
      orders: [{ field: "start_time", direction: "asc" }],
      limit: withLimit
    });
    return docs.map(x => mapEntity(x));
  }
  async searchFromOnlineBooking() {
    const fromDate = new Date();
    const appointments = await CallableFunctions.current.public.getFutureCustomerAppointments(10);
    appointments.sortByFieldAscending(x => x.start_time);
    return appointments.filter(x => x.start_time > fromDate);
  }
  async searchPreviousFromOnlineBooking() {
    const toDate = new Date();
    const appointments = await CallableFunctions.current.public.getPastCustomerAppointments();
    appointments.sortByFieldDescending(x => x.start_time);
    return appointments.filter(x => x.start_time < toDate);
  }
  async searchPastOccurrences(recurrenceIds: string[]) {
    const toDate = new Date();
    const fromDate = getDatePlusDays(toDate, -(8 * 7 * 3), TimeZone.PacificTime);
    return new Promise<Appointment[]>(resolveAll => {
      if (!recurrenceIds.length) return resolveAll([]);

      const batches: Promise<Appointment[]>[] = [];

      while (recurrenceIds.length) {
        const batch = recurrenceIds.splice(0, MAX_IN_PARAMETERS_LENGTH);

        batches.push(
          new Promise(resolve => {
            this.firestoreService.search({
              filters: [
                getFilter("start_time", FilterOperator.lessThan, toDate),
                getFilter("start_time", FilterOperator.greaterThan, fromDate),
                getFilter("status", FilterOperator.equal, AppointmentStatus.completed),
                getFilter("recurrence_id", FilterOperator.in, batch)
              ],
              orders: [{ field: "start_time", direction: "desc" }]
            }).then(docs =>
              resolve(docs.map(x => mapEntity(x))));
          })
        );
      }

      Promise.all(batches).then(content => {
        resolveAll(content.flat());
      });
    });
  }
  listenChanges(id: string, callback: (data: Appointment) => void) {
    return this.firestoreService.onDocumentSnapshot(id, snapshot => {
      if (!snapshot) return;
      callback(mapEntity(snapshot));
    });
  }
  listenForCustomer(customerId: string, callback: (appointments: Appointment[]) => void): () => void {
    return this.firestoreService.onQuerySnapshot({
      filters: [
        getFilter("customer.id", FilterOperator.equal, customerId)
      ],
      orders: [
        { field: "start_time", direction: "desc" }
      ]
    }, docs => {
      callback(docs.map(x => mapEntity(x)));
    });
  }
  listenFromCalendar(day: LocalDate, isForAllHubs: boolean, callback: (appointments: Appointment[]) => void): () => void {

    const filters = [
      getFilter<AppointmentData>("start_time", FilterOperator.greaterThanOrEqual, day.toDayStart()),
      getFilter<AppointmentData>("start_time", FilterOperator.lessThanOrEqual, day.toDayEnd())
    ];

    if (!isForAllHubs) {
      filters.push(
        getFilter<AppointmentData>("time_zone", FilterOperator.equal, day.contextTimeZone)
      );
    }

    return this.firestoreService.onQuerySnapshot({ filters },
      docs => {
        const appointments = docs.map(x => mapEntity(x));
        callback(appointments.filter(x => x.statusIn.calendarStatuses));
      });
  }
  listenFromGroomerCalendar(day: LocalDate, groomerId: string, callback: (appointments: Appointment[]) => void): () => void {
    const filters = [
      getFilter<AppointmentData>("start_time", FilterOperator.greaterThanOrEqual, day.toDayStart()),
      getFilter<AppointmentData>("start_time", FilterOperator.lessThanOrEqual, day.toDayEnd()),
      getFilter<AppointmentData>("time_zone", FilterOperator.equal, day.contextTimeZone),
      getFilter<AppointmentData>("groomer.id", FilterOperator.equal, groomerId)
    ];

    return this.firestoreService.onQuerySnapshot({ filters },
      docs => {
        const appointments = docs.map(x => mapEntity(x));
        callback(appointments.filter(x => x.statusIn.calendarStatuses));
      });
  }

  async searchByIds(ids: string[]) {
    const docs = await this.firestoreService.searchInBatchByIds(ids);
    return docs.map(x => mapEntity(x));
  }

  static getColorStyles(isPersonalEvent?: boolean, status?: string, paymentStatus?: string, hasServiceArrivalTime?: boolean, isCheckedIn?: boolean): { backgroundColor?: string, color: string, background?: string } {
    const scheduledColor = hasServiceArrivalTime || isCheckedIn
      ? calendarEventStyles.lightBlueAccent
      : calendarEventStyles.blueBackground;

    const paidColor = status === AppointmentStatus.scheduled ? scheduledColor :
      calendarEventStyles.lightBlueBackground;

    if (isPersonalEvent) {
      return calendarEventStyles.lightGrayBackground;
    }
    else if (paymentStatus && status) {
      switch (paymentStatus) {
        case PaymentStatus.paid:
        case PaymentStatus.fee_waived:
          return paidColor;
        case PaymentStatus.awaiting_payment:
          return calendarEventStyles.blackBackground;
        case PaymentStatus.bad_debt:
          return calendarEventStyles.redBackground;
        case PaymentStatus.offline_payment:
          return calendarEventStyles.linearGradientBackground;
        default:
          return calendarEventStyles.defaultBackground;
      }
    }
    else {
      switch (status) {
        case AppointmentStatus.scheduled:
          return scheduledColor;
        case AppointmentStatus.completed:
          return calendarEventStyles.blackBackground;
        case AppointmentStatus.pending:
        case AppointmentStatus.unconfirmed:
          return calendarEventStyles.yellowBackground;
        case AppointmentStatus.cancelled:
          return calendarEventStyles.lightYellowBackground;
        default:
          return calendarEventStyles.defaultBackground;
      }
    }
  }
  async completeCheckout(id: string, succeeded: boolean, transactionId?: string, errorCode?: string) {
    await this.update(id, Appointment.updateData.forCheckoutCallback(succeeded, transactionId, errorCode));
  }
}
