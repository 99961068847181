import { DateTime } from "luxon";
import { getDatePlusHours, getHoursBetweenDates, getMinutesBetweenDates } from "../helpers/timeHelper";
import { TimeZone } from "../helpers/timeZoneHelper";
import { acceptedImageTypes, acceptedVideoTypes, vcardFileTypes } from "../utilities/FileTypes";
import ArrayGroups from "../utilities/ArrayGroups";
import { Groomer } from "./Groomer";
import { CollectionPaths } from "./base/CollectionPaths";
import { GroomerLog } from "./GroomerLog";
import { CustomerLog } from "./CustomerLog";
import { Customer } from "./Customer";
import { Hub } from "./Hub";

const RESPONSE_TO_GROOMER_WINDOW_HOURS = 2;
const HOURS_FOR_AUTOMATED_ACTIVITY_RESPONSE = 5;
const HOURS_FOR_SAME_CONVERSATION = 48;
const MINUTES_FOR_RECENT_MESSAGE = 5;
const AFTERHOURS_FROM = 18;
const AFTERHOURS_TO = 7;

export const TWILIO_SENT_STATUS = "sent";
export const TWILIO_FAILED_STATUS = "failed";
export const TWILIO_QUEUED_STATUS = "queued";
export const TWILIO_UNDELIVERED_STATUS = "undelivered";
export const TWILIO_DELIVERED_STATUS = "delivered";

type MessageParentType = CollectionPaths.Customers | CollectionPaths.Groomers;

enum MessageMediaType {
    Image = "image",
    Video = "video",
    VCard = "vCard"
}

enum MessageTagStages {
    before = "before",
    during = "during",
    after = "after"
}

enum MessageSender {
    Barkbus = "barkbus",
    Customer = "customer",
    Groomer = "groomer"
}

enum MessageType {
    SMS = "sms",
    Note = "note",
    CallSummary = "call_summary",
    ManagerNote = "manager_note",
    Activity = "activity",
    AutomatedActivity = "automated_activity"
}

enum MessageContactType {
    Main = "main",
    Secondary = "secondary"
}

enum MessageTypeFilter {
    All = "all",
    Messages = "messages",
    Notes = "notes",
    ManagerNotes = "manager_notes",
    Activity = "activity",
    GroomerView = "groomer_view"
}

export enum FunctionResponseStatus {
    success = "success",
    error = "error"
}

export interface MessageTagPetData {
    id: string,
    name: string
}

export interface FileInformation {
    mediaUrl: string
    mediaType: string
}

export interface FunctionResponseContent {
    status: FunctionResponseStatus,
    details?: string
}

interface MessageTagSubset {
    id: string,
    customerId: string,
    tags?: {
        pets?: MessageTagPetData[],
        stage?: MessageTagStages
    },
    media_urls?: string[],
    created_at: Date
}

interface PetTaggedPhotos {
    petId: string,
    taggedImages: string[]
}

interface MessageContactData {
    phone: string,
    type: MessageContactType
    name?: string,
}

interface MessageInputData {
    parentId: string,
    content: string,
    phone?: string,
    files?: FileInformation[],
    secondaryName?: string,
    outcome?: ActivityOutcome,
    sender?: MessageSender,
    userId?: string,
    appointmentId?: string,
    chatbotActivityId?: string,
    noteUrl?: string,
    hiddenContent?: string,
    suggestedAppointmentId?: string,
    isCsatRequest?: true
}

interface MessageData {
    content: string,
    created_at: Date,
    sender: MessageSender,
    type: MessageType,
    sales_type?: SalesType,
    media_urls?: string[],
    //HACK: This field refers to MIME types (should not be confused with our custom MessageMediaType enum). We keep the original name for backwards compatibility.
    media_types?: string[],
    contact?: MessageContactData,
    //HACK: This field is meant to be the user/groomer uid, and not the document's id. We keep the original name for backwards compatibility.
    user_id?: string,
    twilio_sid?: string,
    twilio_status?: string,
    error_code?: string,
    error_message?: string,
    tags?: {
        pets?: MessageTagPetData[],
        stage?: MessageTagStages
    },
    outcome?: ActivityOutcome,
    first_reply_content?: string,
    has_been_effective?: true,
    postcard_sent?: true,
    afterhours_sent?: true,
    is_appointment_reminder?: true,
    is_csat_request?: true,
    suggested_appointment_id?: string,
    appointment_id?: string,
    chatbot_activity_id?: string,
    chatbot_queued?: true,
    note_url?: string,
    hidden_content?: string,
    has_media?: true,
    from_groombuggy?: true,
    sent_from_phone?: string
}

interface ChatbotFunctionCall {
    id: string,
    function_name: string,
    function_arguments: string
}

interface ChatbotFunctionCallResponse {
    id: string,
    function_name: string
}

enum ChatbotMessageRole {
    system = "system",
    assistant = "assistant",
    user = "user",
    function = "function"
}

enum SalesType {
    lapsed_lead = "lapsed_lead",
    lapsed_customer = "lapsed_customer",
    automated_hunting = "automated_hunting",
    marketing_sms = "marketing_sms"
}

interface ChatbotRequestMessage {
    role: ChatbotMessageRole;
    content: string;
    functionCall?: ChatbotFunctionCall;
    functionCallResponse?: ChatbotFunctionCallResponse;
}

interface ChatbotConversationMessage extends ChatbotRequestMessage {
    date: Date;
    senderName?: string;
}

interface Message extends MessageData {
    id: string,
    parentType: MessageParentType,
    parentId: string
}

class Message {
    constructor(id: string, parentType: MessageParentType, parentId: string, data: MessageData) {
        this.id = id;
        this.parentType = parentType;
        this.parentId = parentId;
        Object.assign(this, data);
    }
    toData(): MessageData {
        const { id, parentType, parentId, ...data } = this;
        return data;
    }
    static getMediaType(mediaIdx: number, media_types?: string[]) {
        if (media_types === undefined) {
            return MessageMediaType.Image;
        }
        else {
            const originalMediaType = media_types[mediaIdx];
            if (originalMediaType === undefined)
                throw new Error(`Missing required media type at index ${mediaIdx}`);

            const mediaType = originalMediaType.toLowerCase();

            if (vcardFileTypes.includes(mediaType))
                return MessageMediaType.VCard;
            else if (acceptedImageTypes.includes(mediaType))
                return MessageMediaType.Image;
            else if (acceptedVideoTypes.includes(mediaType))
                return MessageMediaType.Video;
            else {
                return undefined;
            }
        }
    }
    static getContact(phone: string, secondaryName?: string) {
        return ({
            phone,
            type: secondaryName ? MessageContactType.Secondary : MessageContactType.Main,
            name: secondaryName
        });
    }
    static getMediaFields(files?: FileInformation[]): Partial<MessageData> {
        return ({
            media_urls: files && files.length > 0 ? files.map(x => x.mediaUrl) : undefined,
            media_types: files && files.length > 0 ? files.map(x => x.mediaType) : undefined,
            has_media: files && files.length > 0 ? true : undefined
        });
    }
    static smsCompatibleTypes() {
        return [
            MessageType.SMS,
            MessageType.AutomatedActivity
        ];
    }
    static allVisibleTypes() {
        return [
            MessageType.SMS,
            MessageType.Note,
            MessageType.CallSummary,
            MessageType.ManagerNote,
            MessageType.Activity,
            MessageType.AutomatedActivity
        ];
    }
    isFromCustomer() {
        return this.sender === MessageSender.Customer;
    }
    isFromGroomer(groomers: Groomer[]) {
        return groomers.some(groomer => this.user_id === groomer.uid);
    }
    qualifiesAsResponseToGroomer(businessMessage: Message, groomers: Groomer[]) {
        const isFromGroomer = businessMessage.isFromGroomer(groomers);
        const isWithinWindow = getHoursBetweenDates(businessMessage.created_at, this.created_at) < RESPONSE_TO_GROOMER_WINDOW_HOURS;
        return (
            isFromGroomer && isWithinWindow
        );
    }
    qualifiesForChatbot(businessMessage: Message | null) {
        return this.isValidSmsFromCustomer() && (
            !businessMessage || this.qualifiesAsResponseToAutomatedActivity(businessMessage)
        );
    }
    private isValidSmsFromCustomer() {
        return (
            !this.isEmptyMessage() &&
            this.type === MessageType.SMS &&
            this.sender === MessageSender.Customer
        );
    }
    isValidSmsFromGroomer() {
        return (
            !this.isEmptyMessage() &&
            !this.isLikeMessage() &&
            this.type === MessageType.SMS &&
            this.sender === MessageSender.Groomer
        );
    }
    isEmptyMessage() {
        return !this.content || this.content.trim().length === 0;
    }
    isLikeMessage() {
        const regex = /\bLiked\s*"/;
        return regex.test(this.content);
    }
    qualifiesAsResponseToAutomatedActivity(businessMessage: Message) {
        return (
            businessMessage.sender === MessageSender.Barkbus &&
            (businessMessage.type === MessageType.AutomatedActivity || !!businessMessage.is_appointment_reminder) &&
            !businessMessage.chatbot_activity_id &&
            this.sender === MessageSender.Customer &&
            getDatePlusHours(businessMessage.created_at, HOURS_FOR_AUTOMATED_ACTIVITY_RESPONSE) > this.created_at
        );
    }
    isInSameConversation(businessMessage: Message) {
        return (
            businessMessage.sender === MessageSender.Barkbus &&
            this.sender === MessageSender.Customer &&
            getDatePlusHours(businessMessage.created_at, HOURS_FOR_SAME_CONVERSATION) > this.created_at
        );
    }
    isRecent() {
        return getMinutesBetweenDates(this.created_at, new Date()) < MINUTES_FOR_RECENT_MESSAGE;
    }
    static get senderOptions() {
        return {
            isDefaultOrLegacy: (optionId: string) => {
                return (
                    optionId === Message.senderOptions.barkbusDefault.id ||
                    optionId === Message.senderOptions.groombuggyLegacy.id
                );
            },
            barkbusDefault: {
                id: "DEFAULT",
                label: "Barkbus HQ"
            },
            groombuggyLegacy: {
                id: "GB-LEGACY",
                label: "Groombuggy Legacy"
            },
            getOptionIdFromCustomer: (customer: Customer) => {
                if (customer.twilio_hub_id) {
                    return customer.twilio_hub_id;
                }
                else if (customer.twilio_groombuggy_legacy) {
                    return Message.senderOptions.groombuggyLegacy.id;
                }
                else {
                    return Message.senderOptions.barkbusDefault.id;
                }
            },
            getLabel: (optionId: string, hubs: Hub[]) => {
                const twilioHub = hubs.find(x => x.id === optionId);
                if (twilioHub) {
                    return `${twilioHub.acronym} hub`;
                }
                else if (optionId === Message.senderOptions.groombuggyLegacy.id) {
                    return Message.senderOptions.groombuggyLegacy.label;
                }
                else {
                    return Message.senderOptions.barkbusDefault.label;
                }
            }
        };
    }
    static churnActivityOutcomes() {
        return [
            ActivityOutcome.customer_churn_moved,
            ActivityOutcome.customer_churn_pet_passed,
            ActivityOutcome.customer_churn_price,
            ActivityOutcome.customer_churn_service_issue,
            ActivityOutcome.customer_churn_stylist_departure,
            ActivityOutcome.customer_churn_other,
        ];
    }
    static groupMediaUrlsByPet(messages: MessageData[] | MessageTagSubset[]): PetTaggedPhotos[] {
        const allTaggedPhotos = messages.flatMap(({ tags = {}, media_urls = [], created_at }) => {
            const mediaUrl = media_urls.at(0);
            const { pets = [] } = tags;
            if (pets.length && mediaUrl) {
                return pets.map(({ id }) => ({ url: mediaUrl, created_at, petId: id }));
            }
            return [];
        });

        const taggedPhotosByPet = ArrayGroups.getBy(allTaggedPhotos, x => x.petId);

        taggedPhotosByPet.forEach(images => {
            images.sort((a, b) => a.created_at.getTime() - b.created_at.getTime());
        });

        const petTaggedPhotos = Array.from(taggedPhotosByPet, ([petId, taggedImages]) => ({
            petId,
            taggedImages: taggedImages.map(x => x.url)
        }));

        return petTaggedPhotos;
    }
    static getAfterhoursWindow() {
        return 24 - AFTERHOURS_FROM + AFTERHOURS_TO;
    }
    static isAfterhours(date: Date, timeZone: TimeZone) {
        const hour = DateTime.fromJSDate(date).setZone(timeZone).hour;
        return hour >= AFTERHOURS_FROM || hour < AFTERHOURS_TO;
    }
    //TODO: Refactor when we unify CustomerLog and GroomerLog classes
    static isLog(item: Message | GroomerLog | CustomerLog): item is GroomerLog | CustomerLog {
        return (
            item instanceof GroomerLog || item instanceof CustomerLog
        );
    }
    getImageUrl() {
        return this.media_urls?.find((_, idx) => Message.getMediaType(idx, this.media_types) === MessageMediaType.Image);
    }
    hasImage() {
        return (
            !!this.getImageUrl()
        );
    }
    static get styles() {
        return {
            color: (isFromSecondary: boolean, isToSecondary: boolean) => {
                return (
                    isFromSecondary ? "#9a79ff" : isToSecondary ? "#f3efff" : "#3c4858"
                );
            }
        };
    }
}

enum ActivityOutcome {
    answered_call_escalation = "Answered Call: Escalation",
    answered_call_rescheduled_appointment = "Answered Call: Rescheduled Appointment",
    answered_call_booked_appointment = "Answered Call: Booked Appointment",
    answered_call_did_not_book = "Answered Call: Did not book",
    answered_call_other = "Answered Call: Other",
    called_appointment_follow_up = "Called: Appointment Follow-Up",
    called_booked_appointment = "Called: Booked Appointment",
    called_booked_recurring = "Called: Booked Recurring",
    called_did_not_book = "Called: Did not book",
    called_other = "Called: Other",
    called_rescheduled_appointment = "Called: Rescheduled Appointment",
    called_unable_to_reach = "Called: Unable to reach, left VM",
    texted_booked_appointment = "Texted: Booked Appointment",
    texted_booked_recurring = "Texted: Booked Recurring",
    texted_no_reply = "Texted: No Reply",
    other_email_or_other_correspondence = "Other: Email or other correspondence",
    customer_churn_price = "Customer churn: Price",
    customer_churn_moved = "Customer churn: Moved",
    customer_churn_pet_passed = "Customer churn: Pet passed",
    customer_churn_service_issue = "Customer Churn: Service Issue",
    customer_churn_stylist_departure = "Customer Churn: Pet Stylist Departure",
    customer_churn_other = "Customer churn: Other"
}

export { Message, MessageParentType, MessageMediaType, MessageTagStages, MessageSender, MessageType, MessageTypeFilter, MessageContactType, ChatbotMessageRole, SalesType, ActivityOutcome };
export type { MessageData, MessageInputData, MessageContactData, ChatbotConversationMessage, ChatbotFunctionCall, ChatbotFunctionCallResponse, ChatbotRequestMessage, PetTaggedPhotos, MessageTagSubset };
